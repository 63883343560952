import { createStyles, Theme } from "@material-ui/core";
const Styles = (theme: Theme) =>
    createStyles({
        card: {
            position: "relative",
            padding: theme.spacing(2),
            marginLeft: theme.spacing(1),
            paddingTop: theme.spacing(1),
            [theme.breakpoints.up("sm")]: {
                padding: theme.spacing(3),
                paddingTop: theme.spacing(2),
            },
        },
        header: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
        },
        description: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            textOverflow: "ellipsis",
            overflow: "hidden",
            display: "-webkit-box",
            "-webkit-line-clamp": 3,
            "-webkit-box-orient": "vertical",
            whiteSpace: "break-spaces",
        },
        menuItem: {
            "&>svg": {
                marginRight: theme.spacing(0.5),
            },
        },
        loader: {
            position: "absolute",
            left: "50%",
            marginLeft: -20,
            top: "50%",
            marginTop: -20,
        },
    });

export default Styles;
