import React from "react";
import { Redirect, RouteComponentProps } from "react-router";

import { getToken } from "./tokens";

interface IProps extends RouteComponentProps {
    ProtectedComponent: React.ComponentType<RouteComponentProps>;
}
class ProtectedPage extends React.Component<IProps> {
    state = {
        isLoading: true,
        isAuthenticated: false,
    };

    componentDidMount() {
        if (getToken()) {
            this.setState({ isAuthenticated: true, isLoading: false });
        } else {
            this.setState({ isLoading: false });
        }
    }
    render() {
        const { isAuthenticated, isLoading } = this.state;
        const { ProtectedComponent, ...restprops } = this.props;
        const {
            location: { pathname },
        } = this.props;
        if (isLoading) {
            return <div>Loading</div>;
        }
        if (!isLoading && isAuthenticated) {
            return <ProtectedComponent {...restprops} />;
        }
        if (!isLoading && !isAuthenticated) {
            return <Redirect to={{ pathname: "/signin", state: { redirectedFrom: pathname } }} />;
        }
    }
}

export default ProtectedPage;
