import React, { Fragment } from "react";
import { withStyles, AppBar, Toolbar, Button, WithStyles } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { useSnackbar } from "notistack";

import { ReactComponent as FullLogoIcon } from "../../Assets/fullLogo-32px.svg";
import { clearTokens } from "../../utils/tokens";

import Styles from "./styles";

interface IProps extends WithStyles<typeof Styles>, RouteComponentProps {
    drawerIconNeeded: boolean;
    onDrawerIconClick: () => void;
}
const NavBar: React.FC<IProps> = ({ classes, history, drawerIconNeeded, onDrawerIconClick }): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();
    const handleLogout = () => {
        clearTokens();
        enqueueSnackbar("Logged out Successfully.", { variant: "success" });
        history.push("/signin");
    };
    return (
        <Fragment>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    {drawerIconNeeded && (
                        <IconButton color="inherit" aria-label="open drawer" onClick={onDrawerIconClick} edge="start">
                            <MenuIcon />
                        </IconButton>
                    )}
                    <FullLogoIcon />

                    <Button color="inherit" onClick={handleLogout} className={classes.logoutLogo}>
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>
        </Fragment>
    );
};
export default withRouter(withStyles(Styles)(NavBar));
