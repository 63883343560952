import React from "react";
import { withStyles, WithStyles, Card, Typography, Grid, Menu, MenuItem, CircularProgress } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import dayjs from "dayjs";

import useState from "../../utils/StateCustomHook";
import { ExpenseInterface } from "../../utils/Network/Apis/Expense";

import Styles from "./styles";

interface ExpenseCardProps {
    onEditClicked: (expense: ExpenseInterface) => void;
    onDeleteClicked: (expense: ExpenseInterface) => void;
    expense: ExpenseInterface;
    isLoading?: boolean;
}

interface ExpenseCardState {
    anchorEl: null | HTMLElement;
}

const ExpenseCard: React.FC<WithStyles<typeof Styles> & ExpenseCardProps> = ({
    classes,
    expense,
    onDeleteClicked,
    onEditClicked,
    isLoading,
}): JSX.Element => {
    const [state, setState] = useState<ExpenseCardState>({ anchorEl: null });
    const options = [
        {
            id: "2" + expense.id,
            text: "Edit",
            Icon: EditIcon,
            onClick: onEditClicked,
        },
        {
            id: "1" + expense.id,
            text: "Delete",
            Icon: DeleteIcon,
            onClick: onDeleteClicked,
        },
    ];
    const dayjsObj = dayjs(expense.expenseDate);

    return (
        <Card className={classes.card}>
            {isLoading && <CircularProgress className={classes.loader} />}
            <div style={{ visibility: isLoading ? "hidden" : "visible" }}>
                <Grid container alignItems="center">
                    <Grid item xs={10}>
                        <Typography className={classes.header} variant="h5">
                            {expense.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} container justify="flex-end">
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(event) => {
                                setState({ anchorEl: event.currentTarget });
                            }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <Menu
                    anchorEl={state.anchorEl}
                    id="long-menu"
                    keepMounted
                    open={Boolean(state.anchorEl)}
                    onClose={() => {
                        setState({ anchorEl: null });
                    }}
                >
                    {options.map((option) => (
                        <MenuItem
                            className={classes.menuItem}
                            key={option.id}
                            onClick={() => {
                                setState({ anchorEl: null });
                                option.onClick(expense);
                            }}
                        >
                            <option.Icon /> {option.text}
                        </MenuItem>
                    ))}
                </Menu>
                <Typography className={classes.description} variant="body2">
                    {expense.description}
                </Typography>
                <Grid container alignItems="center">
                    <Grid xs={4} item>
                        <Typography variant="subtitle2">{expense.category.name}</Typography>
                    </Grid>
                    <Grid xs={4} item>
                        <Typography align="center" component="div" display="block" variant="overline">
                            {dayjsObj.format("ddd, D, MMM")}
                        </Typography>
                        <Typography align="center" component="div" display="block" variant="overline">
                            {dayjsObj.format("h:mma")}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography align="right" variant="subtitle2">
                            ${expense.amount.toFixed(2)}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </Card>
    );
};

export default withStyles(Styles)(ExpenseCard);
