import { createStyles, Theme } from "@material-ui/core";
const Styles = (theme: Theme) =>
    createStyles({
        root: {
            paddingBottom: theme.spacing(3),
        },
        fab: {
            zIndex: 1,
            position: "fixed",
            bottom: theme.spacing(4),
            right: theme.spacing(4),
        },
        loaderRoot: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    });

export default Styles;
