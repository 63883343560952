import React from "react";
import { RouteComponentProps } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import ReceiptIcon from "@material-ui/icons/Receipt";

import Expenses from "./Expenses";
import Settings from "./Settings";
// import Profile from "./Profile";

export interface PagesListInterface {
    name: string;
    route: string;
    component: React.ComponentType<RouteComponentProps>;
    icon: React.ComponentType;
}

const PagesList: PagesListInterface[] = [
    {
        name: "Expenses",
        component: Expenses,
        route: "/dashboard",
        icon: ReceiptIcon,
    },
    {
        name: "Settings",
        component: Settings,
        route: "/settings",
        icon: SettingsIcon,
    },
    // {
    //     name: "Profile",
    //     component: Profile,
    //     route: "/profile",
    //     icon: SettingsIcon,
    // },
];

export default PagesList;
