export const storeToken = (token: string) => {
    const tokenKey: string = process.env.REACT_APP_TOKEN_STORAGE_KEY as string;
    localStorage.setItem(tokenKey, token);
    //store token
};

export const getToken = () => {
    const tokenKey: string = process.env.REACT_APP_TOKEN_STORAGE_KEY as string;
    return localStorage.getItem(tokenKey);
};

export const clearTokens = () => {
    localStorage.clear();
};
