import { createStyles, Theme } from "@material-ui/core";

const Styles = (theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column",
            width: "auto",
            paddingTop: theme.spacing(2),
            [theme.breakpoints.up("md")]: {
                marginLeft: 240,
            },
            [theme.breakpoints.up("lg")]: {
                marginRight: 240,
            },
        },
    });

export default Styles;
