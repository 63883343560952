import React from "react";
import { withStyles, WithStyles, Container, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import NavBar from "../NavBar";
import MenuDrawer from "../MenuDrawer";
import InternalPagesList from "../../Pages";

import Styles from "./styles";

const Layout: React.SFC<WithStyles<typeof Styles>> = ({ children, classes }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));
    const [isDrawerOpen, setDrawerOpen] = React.useState(false);
    return (
        <React.Fragment>
            <NavBar
                onDrawerIconClick={() => {
                    setDrawerOpen(!isDrawerOpen);
                }}
                drawerIconNeeded={!matches}
            />
            <MenuDrawer open={isDrawerOpen} onDrawerClose={() => setDrawerOpen(false)} drawerList={InternalPagesList} />
            <Container className={classes.container}>{children}</Container>
        </React.Fragment>
    );
};

export default withStyles(Styles)(Layout);
