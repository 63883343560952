import React from "react";
import { Grid, TextField, Typography, Dialog, WithStyles, withStyles, Button } from "@material-ui/core";
import CategoryIcon from "@material-ui/icons/Category";

import { CategoryInterface } from "../../utils/Network/Apis/Categories";
import useState from "../../utils/StateCustomHook";

import { AddCategoryDialogStyles as Styles } from "./styles";

interface AddCategoryDialogProps {
    onClose: () => void;
    open: boolean;
    onAddNewCategory: (categoryName: string) => void;
    categoryBeingEdited?: CategoryInterface;
    onUpdateCategory: (id: string, newCategoryName: string) => void;
}
interface AddCategoryDialogState {
    categoryName: string;
    error: string;
}

const AddCategoryDialog: React.SFC<AddCategoryDialogProps & WithStyles<typeof Styles>> = ({
    onClose,
    open,
    classes,
    onAddNewCategory,
    categoryBeingEdited = null,
    onUpdateCategory,
}) => {
    const [state, setState] = useState<AddCategoryDialogState>({
        categoryName: "",
        error: "",
    });
    const handleCategoryNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ categoryName: event.currentTarget.value });
    };

    React.useEffect(() => {
        setState({ categoryName: categoryBeingEdited ? categoryBeingEdited.name : "" });
    }, [categoryBeingEdited]);

    const onCancel = () => {
        onClose();
        setState({ categoryName: "" });
    };

    const onSubmit = () => {
        //TODO: validate
        if (!state.categoryName || categoryBeingEdited?.name === state.categoryName) {
            setState({ error: "Enter the the new name for category" });
            return;
        }

        if (categoryBeingEdited) {
            //TODO:Validate
            if (state.categoryName === categoryBeingEdited.name) return;
            onUpdateCategory(categoryBeingEdited.id, state.categoryName);
            onCancel();
            return;
        }
        onAddNewCategory(state.categoryName);
        onCancel();
    };

    const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            onSubmit();
        }
    };

    return (
        <Dialog classes={{ paper: classes.paper }} onClose={onCancel} open={open} aria-labelledby="budgetUpdate-dialog">
            <Typography variant="h5" className={classes.header}>
                <CategoryIcon className={classes.headerIcon} />
                {categoryBeingEdited ? "Update" : "Add"} Category
            </Typography>

            <TextField
                value={state.categoryName}
                onChange={handleCategoryNameChange}
                label={`${categoryBeingEdited ? "Update" : "Add"} Category`}
                variant="outlined"
                placeholder={`${categoryBeingEdited ? "Update" : "Add"} Category`}
                className={classes.categoryInputField}
                onKeyPress={onKeyPress}
                autoFocus
            />
            <Grid container justify="flex-end">
                <Grid item>
                    <Button onClick={onSubmit} variant="contained" color="primary" className={classes.updateButton}>
                        {categoryBeingEdited ? "Update" : "Add"}
                    </Button>
                </Grid>
                <Grid item>
                    <Button onClick={onCancel} variant="text">
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default withStyles(Styles)(AddCategoryDialog);
