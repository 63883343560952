import Router from "../router";
import { CategoriesRoute } from "../ApiRoutes";
import { getToken } from "../../tokens";

export interface CategoryInterface {
    createdAt: number;
    isDeleted: boolean;
    name: string;
    user: string;
    id: string;
}

class Categories extends Router {
    constructor() {
        super(CategoriesRoute);
    }
    // private route = CategoriesRoute;
    public getAll = async () => {
        try {
            const token = getToken();
            if (token === null) throw new Error("no token");
            return await this.request<CategoryInterface[]>({
                method: this.GET,
                headers: { Authorization: "Bearer " + token },
            });
        } catch (error) {
            if (error.response) throw error.response.data;
            else throw error;
        }

        //form all the params
        // return request promise
    };

    public create = async (name: string) => {
        try {
            const token = getToken();
            if (token === null) throw new Error("no token");
            return await this.request<CategoryInterface>({
                method: this.POST,
                headers: { Authorization: "Bearer " + token },
                data: { name },
            });
        } catch (error) {
            if (error.response) throw error.response.data;
            else throw error;
        }
    };

    public delete = async (id: string) => {
        try {
            const token = getToken();
            if (token === null) throw new Error("no token");
            return await this.request<CategoryInterface>({
                url: this.route + "/" + id,
                method: this.DELETE,
                headers: { Authorization: "Bearer " + token },
            });
        } catch (error) {
            if (error.response) throw error.response.data;
            else throw error;
        }
    };

    public update = async (id: string, name: string) => {
        try {
            const token = getToken();
            if (token === null) throw new Error("no token");
            const response = await this.request<CategoryInterface>({
                url: this.route + "/" + id,
                method: this.PUT,
                headers: { Authorization: "Bearer " + token },
                data: {
                    name,
                },
            });
            return response;
        } catch (error) {
            if (error.response) throw error.response.data;
            else throw error;
        }
    };
}

export default Categories;
