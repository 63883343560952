import Router from "../router";
import { AuthRoute } from "../ApiRoutes";

interface AuthInterface {
    authToken: string;
}

class Auth extends Router {
    /**
     *
     */
    constructor() {
        super(AuthRoute);
    }

    public login = async (email: string, password: string) => {
        try {
            const response = await this.request<AuthInterface>({
                method: this.POST,
                url: this.route + "/login",
                data: {
                    email,
                    password,
                },
            });
            return response;
        } catch (error) {
            if (error.response) throw error.response.data;
            else throw error;
        }
    };

    public register = async (email: string, password: string, firstName: string, lastName: string) => {
        try {
            const response = await this.request<AuthInterface>({
                method: this.POST,
                url: this.route + "/register",
                data: {
                    email,
                    password,
                    firstName,
                    lastName,
                },
            });
            return response;
        } catch (error) {
            if (error.response) throw error.response.data;
            else throw error;
        }
    };
}

export default Auth;
