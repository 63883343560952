import React from "react";
import { Helmet as ReactHelmet } from "react-helmet";

interface HelmetProps {
    description?: string;
    lang?: string;
    meta?: { name: string; content: string }[];
    title: string;
}
const Helmet: React.SFC<HelmetProps> = ({
    title,
    description = "Expenseev - Expense Tracking application",
    lang = "en",
    meta = [],
}) => {
    const staticTitle = "Expenseev - Expense Tracking application";
    return (
        <ReactHelmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${staticTitle}`}
            meta={[
                {
                    name: `description`,
                    content: description,
                },
            ].concat(meta)}
        />
    );
};

export default Helmet;
