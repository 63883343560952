import Router from "../router";
import { BudgetRoute } from "../ApiRoutes";
import { getToken } from "../../tokens";

// interface BudgetInterface {
//     authToken: string;
// }

class Budget extends Router {
    /**
     *
     */
    constructor() {
        super(BudgetRoute);
    }

    public getBudget = async () => {
        try {
            const token = getToken();
            if (token === null) throw new Error("no token");
            return await this.request<number>({
                method: this.GET,
                headers: { Authorization: "Bearer " + token },
            });
        } catch (error) {
            if (error.response) throw error.response.data;
            else throw error;
        }
    };

    public updateBudget = async (budget: number) => {
        try {
            const token = getToken();
            if (token === null) throw new Error("no token");
            return await this.request<number>({
                method: this.PUT,
                headers: { Authorization: "Bearer " + token },
                data: { budget },
            });
        } catch (error) {
            if (error.response) throw error.response.data;
            else throw error;
        }
    };
}

export default Budget;
