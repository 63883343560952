import React from "react";
import {
    withStyles,
    WithStyles,
    TextField,
    Typography,
    Box,
    Dialog,
    Theme,
    createStyles,
    Button,
} from "@material-ui/core";
import MoneyIcon from "@material-ui/icons/Money";

import useSetState from "../../utils/StateCustomHook";

// import Styles from "./styles";

const Styles = (theme: Theme) =>
    createStyles({
        paper: {
            width: "80vw",
            padding: theme.spacing(3),
        },
        header: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(2),
        },
        updateButton: {
            marginRight: theme.spacing(1),
        },
        budgetInputField: {
            marginBottom: theme.spacing(3.5),
        },
    });

interface BudgetUpdateDialogProps {
    onClose: () => void;
    open: boolean;
    currentBudget?: number;
    onUpdateClick: (value: number) => void;
}

interface BudgetUpdateDialogState {
    budgetValue?: string;
    isBudgetInputTouched: boolean;
}
const BudgetUpdateDialog: React.FC<BudgetUpdateDialogProps & WithStyles<typeof Styles>> = ({
    classes,
    onClose,
    open,
    currentBudget,
    onUpdateClick,
}): JSX.Element => {
    const [state, setState] = useSetState<BudgetUpdateDialogState>({ isBudgetInputTouched: false });

    const onUpdateBudgetTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!state.isBudgetInputTouched) {
            setState({ isBudgetInputTouched: true, budgetValue: event.currentTarget.value });
            return;
        }
        //check if enter key is pressed
        setState({ budgetValue: event.currentTarget.value });
    };

    const onSubmit = () => {
        if (!state.budgetValue || parseFloat(state.budgetValue) === currentBudget) return;
        onClose();
        onUpdateClick(parseFloat(state.budgetValue || "0"));
    };

    const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            onSubmit();
        }
    };

    return (
        <Dialog classes={{ paper: classes.paper }} onClose={onClose} open={open} aria-labelledby="budgetUpdate-dialog">
            <Typography variant="h5" className={classes.header}>
                <Box clone mr={0.5}>
                    <MoneyIcon />
                </Box>
                Budget
            </Typography>
            <TextField
                value={state.isBudgetInputTouched ? state.budgetValue : state.budgetValue || currentBudget}
                onChange={onUpdateBudgetTextChange}
                label="New Budget"
                onKeyPress={onKeyPress}
                type="number"
                variant="outlined"
                placeholder="New Budget"
                className={classes.budgetInputField}
                autoFocus
            />
            <Box display="flex" justifyContent="flex-end">
                <Button onClick={onSubmit} variant="contained" color="primary" className={classes.updateButton}>
                    Update
                </Button>
                <Button onClick={onClose} variant="text">
                    Cancel
                </Button>
            </Box>
        </Dialog>
    );
};
export default withStyles(Styles)(BudgetUpdateDialog);
