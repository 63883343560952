import { createStyles, Theme } from "@material-ui/core";

const Styles = (theme: Theme) =>
    createStyles({
        header: {
            marginBottom: theme.spacing(2),
        },
        headerIcon: {
            marginRight: theme.spacing(0.5),
        },
    });

export default Styles;
