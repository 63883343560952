import axios, { Method, AxiosRequestConfig } from "axios";

import { BaseURL } from "./ApiRoutes";

interface CommonResponseInterface<T> {
    success: boolean;
    data: T;
}
interface IRequest extends AxiosRequestConfig {
    url?: string;
    method: Method;
}
class Router {
    protected POST: Method = "post";
    protected GET: Method = "get";
    protected PUT: Method = "put";
    protected DELETE: Method = "DELETE";
    protected route: string;

    constructor(route?: string) {
        this.route = route || "";
    }

    protected async request<T>(params: IRequest) {
        const response = await axios.request<CommonResponseInterface<T>>({
            url: this.route,
            baseURL: BaseURL,
            ...params,
        });
        return response.data;
    }
}

export default Router;
