import React, { Fragment } from "react";
import { withStyles, WithStyles, CircularProgress, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useSnackbar } from "notistack";

import useState from "../../utils/StateCustomHook";
import ExpenseDialog from "../../Components/ExpenseDialog";
import ExpensesAPI, { ExpenseInterface } from "../../utils/Network/Apis/Expense";
import ExpenseCard from "../../Components/ExpenseCard";
import Helmet from "../../Components/Helmet";

import Styles from "./styles";

interface ExpensePageState {
    isExpenseDialogOpen: boolean;
    expenses: (ExpenseInterface & { isLoading?: boolean })[];
    expenseBeingEdited?: ExpenseInterface;
    isExpensesApiLoading: boolean;
}

const expenseAPI = new ExpensesAPI();

const ExpensesPage: React.FC<WithStyles<typeof Styles> & RouteComponentProps> = ({ classes }): JSX.Element => {
    const [state, setState] = useState<ExpensePageState>({
        isExpenseDialogOpen: false,
        expenses: [],
        expenseBeingEdited: undefined,
        isExpensesApiLoading: true,
    });
    const { enqueueSnackbar } = useSnackbar();
    const handleAddExpenseClick = () => {
        setState({ isExpenseDialogOpen: true, expenseBeingEdited: undefined });
    };

    const handleCloseExpenseDialog = () => {
        setState({ isExpenseDialogOpen: false });
    };

    const handleAddExpenseComplete = (expense: ExpenseInterface) => {
        setState((prevState) => ({ isExpenseDialogOpen: false, expenses: [...prevState.expenses, expense] }));
    };

    const handleEditExpenseClick = (expense: ExpenseInterface) => {
        setState({ expenseBeingEdited: expense, isExpenseDialogOpen: true });
    };

    const handleEditExpenseComplete = (expense: ExpenseInterface) => {
        setState((prevState) => {
            const expenseIndex = prevState.expenses.findIndex((eachExpense) => eachExpense.id === expense.id);
            const { expenses } = prevState;
            expenses[expenseIndex] = expense;
            return {
                expenses,
                isExpenseDialogOpen: false,
            };
        });
    };

    const handleDeleteExpenseClick = (expense: ExpenseInterface) => {
        // set the expense to a loading state
        setState(({ expenses }) => {
            const expenseToBeDeletedIndex = expenses.findIndex((eachExpense) => eachExpense.id === expense.id);
            expenses[expenseToBeDeletedIndex].isLoading = true;
            return { expenses };
        });
        //Call Delete api
        if (expense.id)
            expenseAPI
                .delete(expense.id)
                .then(() => {
                    setState((prevState) => {
                        const newExpenses = prevState.expenses.filter((eachExpense) => eachExpense.id !== expense.id);
                        return { expenses: newExpenses };
                    });
                    enqueueSnackbar(`"${expense.name}" deleted successfully`, { variant: "success" });
                })
                .catch((err) => {
                    if (err?.error?.code === 4008) {
                        enqueueSnackbar(`"${expense.name}" has already been deleted`, { variant: "error" });
                        setState((prevState) => {
                            const newExpenses = prevState.expenses.filter(
                                (eachExpense) => eachExpense.id !== expense.id,
                            );
                            return { expenses: newExpenses };
                        });
                        return;
                    }
                    enqueueSnackbar(`"${expense.name}" could not be deleted, please try again`, { variant: "error" });
                    // set the loading key false so that user can try again
                    setState(({ expenses }) => {
                        const expenseToBeDeletedIndex = expenses.findIndex(
                            (eachExpense) => eachExpense.id === expense.id,
                        );
                        expenses[expenseToBeDeletedIndex].isLoading = false;
                        return { expenses };
                    });
                });
        //remove from state
    };

    React.useLayoutEffect(() => {
        expenseAPI
            .getAll()
            .then((response) => {
                setState({ expenses: response.data, isExpensesApiLoading: false });
            })
            .catch((_err) => {
                enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
            });
    }, [enqueueSnackbar]);

    return (
        <Fragment>
            <Helmet title="Expenses" />
            {state.isExpensesApiLoading ? (
                <div className={classes.loaderRoot}>
                    <CircularProgress />
                </div>
            ) : (
                <Fragment>
                    <ExpenseDialog
                        expenseBeingEdited={state.expenseBeingEdited}
                        onAddExpenseComplete={handleAddExpenseComplete}
                        onClose={handleCloseExpenseDialog}
                        isOpen={state.isExpenseDialogOpen}
                        onEditExpenseComplete={handleEditExpenseComplete}
                    />
                    <div className={classes.root}>
                        <Fab className={classes.fab} color="primary" aria-label="add" onClick={handleAddExpenseClick}>
                            <AddIcon />
                        </Fab>
                        {!state.isExpensesApiLoading && state.expenses.length === 0 ? (
                            <div className={classes.loaderRoot}>
                                <Typography variant="h6">No expense recorded yet. Please add an expense.</Typography>
                            </div>
                        ) : (
                            state.expenses.map((eachExpense) => (
                                <ExpenseCard
                                    key={eachExpense.id}
                                    expense={eachExpense}
                                    onDeleteClicked={handleDeleteExpenseClick}
                                    onEditClicked={handleEditExpenseClick}
                                    isLoading={eachExpense.isLoading}
                                />
                            ))
                        )}
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};
export default withStyles(Styles)(ExpensesPage);
