import { createStyles, Theme } from "@material-ui/core";

const Styles = (theme: Theme) =>
    createStyles({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
        },
        logoutLogo: {
            marginLeft: "auto",
        },
        toolbarSpaceEscaper: theme.mixins.toolbar,
    });

export default Styles;
