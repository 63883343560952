import React from "react";
import {
    withStyles,
    WithStyles,
    Dialog,
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Button,
    FormHelperText,
} from "@material-ui/core";
import ReceiptIcon from "@material-ui/icons/Receipt";
import "@availity/yup"; // required by yup to support dayjs
import { Formik, FormikHelpers, Form } from "formik";
import * as yup from "yup";
import { DateTimePicker, LocalizationProvider } from "@material-ui/pickers";
import DayjsUtils from "@material-ui/pickers/adapter/dayjs";
import { useSnackbar } from "notistack";
import dayjs, { Dayjs } from "dayjs";

import FormikField from "../../Components/FormikField";
import ButtonWithLoader from "../ButtonWithLoader";
import CategoriesAPI, { CategoryInterface } from "../../utils/Network/Apis/Categories";
import useState from "../../utils/StateCustomHook";
import ExpensesAPI, { ExpenseInterface } from "../../utils/Network/Apis/Expense";

import Styles from "./styles";

const categoriesApi = new CategoriesAPI();

interface ExpenseDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onAddExpenseComplete: (expense: ExpenseInterface) => void;
    expenseBeingEdited?: ExpenseInterface;
    onEditExpenseComplete: (expense: ExpenseInterface) => void;
}

const ExpenseSchema = yup.object().shape({
    expenseName: yup.string().max(255, "Too Long!").required("Name of the expense is required"),
    description: yup.string().required("Description of the expense is required"),
    amount: yup.number().min(0, "Amount cannot be less than 0").required("Amount spent is required"),
    date: yup.date().required(),
    categoryId: yup.string().required("Please select a category from dropdown"),
});

type FormikValuesInterface = Omit<yup.InferType<typeof ExpenseSchema>, "date"> & {
    date: Dayjs;
};

interface ExpenseDialogState {
    categories: CategoryInterface[];
}

const expenseAPI = new ExpensesAPI();
const ExpenseDialog: React.FC<WithStyles<typeof Styles> & ExpenseDialogProps> = ({
    classes,
    isOpen,
    onClose,
    onAddExpenseComplete,
    expenseBeingEdited,

    onEditExpenseComplete,
}): JSX.Element => {
    const [state, setState] = useState<ExpenseDialogState>({ categories: [] });
    const { enqueueSnackbar } = useSnackbar();
    const formikInitialValues: FormikValuesInterface = {
        expenseName: expenseBeingEdited ? expenseBeingEdited.name : "",
        amount: expenseBeingEdited ? expenseBeingEdited.amount : -1,
        description: expenseBeingEdited ? expenseBeingEdited.description : "",
        date: expenseBeingEdited ? dayjs(expenseBeingEdited.expenseDate) : dayjs(),
        categoryId: expenseBeingEdited ? expenseBeingEdited.category.id : "",
    };

    const handleUpdateExpense = (
        values: FormikValuesInterface, //yup.InferType<typeof ExpenseSchema>,
        formikHelpers: FormikHelpers<FormikValuesInterface>, //yup.InferType<typeof ExpenseSchema>>,
    ) => {
        if (expenseBeingEdited && expenseBeingEdited.id)
            expenseAPI
                .update(expenseBeingEdited.id, {
                    amount: values.amount,
                    category: values.categoryId,
                    expenseDate: values.date.unix() * 1000, //TODO: find a way to fix this
                    name: values.expenseName,
                    description: values.description,
                })
                .then((response) => {
                    onEditExpenseComplete(response.data);
                    formikHelpers.setSubmitting(false);
                })
                .catch((_err) => {
                    enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
                    formikHelpers.setSubmitting(false);
                });
    };

    const handleCreateExpense = (
        values: FormikValuesInterface, //yup.InferType<typeof ExpenseSchema>,
        formikHelpers: FormikHelpers<FormikValuesInterface>, //yup.InferType<typeof ExpenseSchema>>,
    ) => {
        expenseAPI
            .create({
                amount: values.amount,
                category: values.categoryId,
                expenseDate: values.date.unix() * 1000, //TODO: find a way to fix this
                name: values.expenseName,
                description: values.description,
            })
            .then((response) => {
                formikHelpers.resetForm();
                formikHelpers.setSubmitting(false);

                onAddExpenseComplete(response.data);
            })
            .catch(() => {
                enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
                formikHelpers.setSubmitting(false);
            });
    };

    React.useLayoutEffect(() => {
        categoriesApi
            .getAll()
            .then((response) => {
                setState({ categories: response.data });
            })
            .catch((_err) => {
                enqueueSnackbar("Something went wrong, please refresh the page", { variant: "error" });
            });
    }, [enqueueSnackbar]);

    React.useLayoutEffect(() => {
        let missingCategoryInserted = false;
        if (expenseBeingEdited) {
            setState((prevState) => {
                const categoryIndex = prevState.categories.findIndex(
                    (eachCategory) => expenseBeingEdited.category.id === eachCategory.id,
                );
                if (categoryIndex !== -1) return prevState;
                missingCategoryInserted = true;
                return { categories: [...prevState.categories, expenseBeingEdited.category] };
            });

            return () => {
                if (missingCategoryInserted)
                    setState((prevState) => {
                        const newCategories = prevState.categories.filter(
                            (eachExpense) => eachExpense.id !== expenseBeingEdited.category.id,
                        );

                        return { categories: newCategories };
                    });
            };
        }
        return undefined;
    }, [expenseBeingEdited]);
    return (
        <LocalizationProvider dateAdapter={DayjsUtils}>
            <Dialog classes={{ paper: classes.paper }} onClose={onClose} open={isOpen} aria-labelledby="expense-dialog">
                <Typography variant="h5" className={classes.header}>
                    <ReceiptIcon />
                    {expenseBeingEdited ? "Update" : "Add"} Expense
                </Typography>
                <Formik
                    onSubmit={expenseBeingEdited ? handleUpdateExpense : handleCreateExpense}
                    initialValues={formikInitialValues}
                    validationSchema={ExpenseSchema}
                >
                    {({ errors, touched, isSubmitting, handleChange, values, setFieldValue, resetForm }) => {
                        return (
                            <Form>
                                <FormikField
                                    Component={TextField}
                                    disabled={isSubmitting}
                                    variant="outlined"
                                    fullWidth
                                    label="Expense name"
                                    placeholder="Expense name"
                                    name="expenseName"
                                    error={touched.expenseName && errors.expenseName !== undefined}
                                    helperText={
                                        touched.expenseName && errors.expenseName !== undefined
                                            ? errors.expenseName
                                            : " "
                                    }
                                />
                                <FormikField
                                    Component={TextField}
                                    disabled={isSubmitting}
                                    variant="outlined"
                                    fullWidth
                                    label="Description"
                                    name="description"
                                    error={touched.description && errors.description !== undefined}
                                    helperText={
                                        touched.description && errors.description !== undefined
                                            ? errors.description
                                            : " "
                                    }
                                />
                                <FormikField
                                    Component={TextField}
                                    disabled={isSubmitting}
                                    variant="outlined"
                                    fullWidth
                                    type="number"
                                    label="Amount Spent"
                                    placeholder="Amount Spent"
                                    name="amount"
                                    error={touched.amount && errors.amount !== undefined}
                                    helperText={touched.amount && errors.amount !== undefined ? errors.amount : " "}
                                    value={values.amount === -1 ? "" : values.amount}
                                />
                                <DateTimePicker
                                    disabled={isSubmitting}
                                    renderInput={(props) => <TextField fullWidth variant="outlined" {...props} />}
                                    value={values.date}
                                    onChange={(data) => setFieldValue("date", data)}
                                    label="Date and Time"
                                    showTodayButton
                                    inputFormat="MMM, DD hh:mm a"
                                />
                                <FormControl
                                    disabled={isSubmitting}
                                    fullWidth
                                    variant="outlined"
                                    error={touched.categoryId && errors.categoryId !== undefined}
                                >
                                    <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select-helper"
                                        name="categoryId"
                                        onChange={handleChange}
                                        value={values.categoryId}
                                        label="Category"
                                    >
                                        {state.categories.map(({ id, name }) => {
                                            return (
                                                <MenuItem key={id} value={id}>
                                                    {name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <FormHelperText>
                                        {touched.categoryId && errors.categoryId !== undefined
                                            ? errors.categoryId
                                            : " "}
                                    </FormHelperText>
                                </FormControl>

                                <Grid container justify="flex-end">
                                    <Grid item>
                                        <ButtonWithLoader
                                            disabled={isSubmitting}
                                            type="submit"
                                            isLoading={isSubmitting}
                                            variant="contained"
                                            color="primary"
                                        >
                                            {expenseBeingEdited ? "Update" : "Add"} Expense
                                        </ButtonWithLoader>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                resetForm();
                                                onClose();
                                            }}
                                            variant="text"
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </Dialog>
        </LocalizationProvider>
    );
};

export default withStyles(Styles)(ExpenseDialog);
