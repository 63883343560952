import React from "react";
import { Typography, Link, WithStyles, withStyles } from "@material-ui/core";
import { Heart } from "mdi-material-ui";

import Styles from "./index.Styles";

const MadeWithLove: React.SFC<WithStyles<typeof Styles>> = ({ classes }) => (
    <Typography variant="overline" className={classes.root}>
        MADE WITH&nbsp;
        <Heart className={classes.icon} />
        &nbsp;BY&nbsp;
        <Link color="inherit" href="https://www.auston.dev">
            AUSTON PRAMODH BARBOZA
        </Link>
    </Typography>
);

export default withStyles(Styles)(MadeWithLove);
