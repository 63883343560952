import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";

interface IProps {
    isLoading: boolean;
}

const ButtonWithLoader: React.FC<IProps & ButtonProps> = (props) => {
    const { children, className, isLoading, ...restProps } = props;
    return (
        <Button className={`${className}`} {...restProps}>
            <span style={{ opacity: isLoading ? 0 : 1 }}>{children}</span>
            {isLoading && (
                <span style={{ color: "white", position: "absolute", display: "flex" }}>
                    <CircularProgress size={16} color="inherit" />
                </span>
            )}
        </Button>
    );
};

export default ButtonWithLoader;
