// import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    "@global": {
        ".exp-nocard-xs": {
            [theme.breakpoints.only("xs")]: {
                boxShadow: "none",
                overflow: "auto",
                background: "none",
            },
        },
    },
}));

export default function GlobalCss() {
    useStyles();

    return null;
}
