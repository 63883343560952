import React from "react";
import { withStyles, WithStyles, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";

import BudgetSettings from "../../Components/BudgetSettings";
import Categories from "../../Components/Categories";
import Helmet from "../../Components/Helmet";

import Styles from "./styles";

const SettingsPage: React.FC<WithStyles<typeof Styles> & RouteComponentProps> = ({ classes }): JSX.Element => {
    return (
        <React.Fragment>
            <Helmet title="Settings" />
            <Typography variant="h4" className={classes.header}>
                <SettingsIcon className={classes.headerIcon} />
                Settings
            </Typography>

            <BudgetSettings />
            <Categories />
        </React.Fragment>
    );
};

export default withStyles(Styles)(SettingsPage);
