import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { CssBaseline, ThemeProvider } from "@material-ui/core";

import SigninPage from "./Pages/Signin";
import SignupPage from "./Pages/Signup";
import NotFound404Page from "./Pages/NotFound404";
import ProtectedPage from "./utils/ProtectedPage";
import Pages from "./Pages";
import Layout from "./Components/Layout";
import CustomTheme from "./Theme";
import GlobalCss from "./utils/GlobalStyles";

const App: React.FC = (): JSX.Element => {
    return (
        <Fragment>
            <CssBaseline />
            <ThemeProvider theme={CustomTheme}>
                <GlobalCss />
                <SnackbarProvider autoHideDuration={5000} maxSnack={3}>
                    <Router>
                        <Switch>
                            <Redirect from="/" exact to="/dashboard" />
                            <Route path="/signin" exact component={SigninPage} />
                            <Route path="/signup" exact component={SignupPage} />
                            <Route path="/404" component={NotFound404Page} />
                            <Route
                                path="/*"
                                render={(props) => (
                                    <Layout>
                                        <Switch {...props}>
                                            {Pages.map(({ component, route }) => (
                                                <Route
                                                    key={route}
                                                    path={route}
                                                    exact
                                                    render={(props) => (
                                                        <ProtectedPage ProtectedComponent={component} {...props} />
                                                    )}
                                                />
                                            ))}
                                            <Redirect from="*" to="/404" />
                                        </Switch>
                                    </Layout>
                                )}
                            />
                        </Switch>
                    </Router>
                </SnackbarProvider>
            </ThemeProvider>
        </Fragment>
    );
};

export default App;
