import React from "react";
import { withStyles } from "@material-ui/core";

import Styles from "./styles";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}
const NotFound404Page: React.FC<IProps> = (): JSX.Element => {
    return <div>NotFound404Page</div>;
};
export default withStyles(Styles)(NotFound404Page);
