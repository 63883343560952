import React, { Fragment } from "react";
import {
    withStyles,
    WithStyles,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Hidden,
    SwipeableDrawer,
    AppBar,
    ListItemIcon,
} from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router";

import { ReactComponent as FullLogoIcon } from "../../Assets/fullLogo-32px.svg";
import { PagesListInterface } from "../../Pages";

import Styles from "./styles";

interface IProps extends WithStyles<typeof Styles>, RouteComponentProps {
    drawerList: PagesListInterface[];
    open: boolean;
    onDrawerOpen?: () => void;
    onDrawerClose?: () => void;
}
const MenuDrawer: React.FC<IProps> = ({
    classes,
    history,
    match,
    drawerList,
    open,
    onDrawerClose = () => undefined,
    onDrawerOpen = () => undefined,
}): JSX.Element => {
    // console.log(match);
    return (
        <Fragment>
            <div className={classes.toolbar} />
            <Hidden smDown implementation="css">
                <SwipeableDrawer
                    className={classes.drawer}
                    open={open}
                    onClose={onDrawerClose}
                    onOpen={onDrawerOpen}
                    // variant="temporary"
                    classes={{ paper: classes.drawerPaper }}
                >
                    <AppBar position="relative" className={classes.appBar}>
                        <FullLogoIcon />
                    </AppBar>
                    <List>
                        {drawerList.map((listItem) => {
                            const Icon = listItem.icon;
                            return (
                                <ListItem
                                    button
                                    key={listItem.route}
                                    onClick={() => {
                                        history.push(listItem.route);
                                        onDrawerClose();
                                    }}
                                    selected={match.url.startsWith(listItem.route)}
                                >
                                    <ListItemIcon>
                                        <Icon />
                                    </ListItemIcon>
                                    <ListItemText primary={listItem.name} />
                                </ListItem>
                            );
                        })}
                    </List>
                </SwipeableDrawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer className={classes.drawer} open variant="persistent" classes={{ paper: classes.drawerPaper }}>
                    <div className={classes.toolbar} />
                    <List>
                        {drawerList.map((listItem) => {
                            const Icon = listItem.icon;
                            return (
                                <ListItem
                                    button
                                    key={listItem.route}
                                    onClick={() => {
                                        history.push(listItem.route);
                                    }}
                                    selected={match.url.startsWith(listItem.route)}
                                >
                                    <ListItemIcon>
                                        <Icon />
                                    </ListItemIcon>
                                    <ListItemText primary={listItem.name} />
                                </ListItem>
                            );
                        })}
                    </List>
                </Drawer>
            </Hidden>
        </Fragment>
    );
};
export default withRouter(withStyles(Styles)(MenuDrawer));
