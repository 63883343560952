import React from "react";
import {
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Table,
    withStyles,
    WithStyles,
    Card,
    IconButton,
    Typography,
    CircularProgress,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CategoryIcon from "@material-ui/icons/Category";

import CategoriesAPI, { CategoryInterface } from "../../utils/Network/Apis/Categories";
import useState from "../../utils/StateCustomHook";

import AddCategoryDialog from "./AddCategoryDialog";
import Styles from "./styles";

type ExtendedCategoryInterface = CategoryInterface & {
    isApiCalling?: boolean;
};

const categoriesApi = new CategoriesAPI();

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CategoryUpdaterProps extends WithStyles<typeof Styles> {}

interface CategoryUpdaterState {
    isCategoryDialogOpen: boolean;
    categoryBeingEdited?: CategoryInterface;
    isAddApiInProgress: boolean;
    isFirstApiCallInProgress: boolean;
    categories: ExtendedCategoryInterface[];
}
const CategoryUpdater: React.FC<CategoryUpdaterProps> = ({ classes }): JSX.Element => {
    const [state, setState] = useState<CategoryUpdaterState>({
        isCategoryDialogOpen: false,
        isAddApiInProgress: false,
        isFirstApiCallInProgress: true,
        categories: [],
    });
    const { enqueueSnackbar } = useSnackbar();
    React.useEffect(() => {
        // TODO: Show Component is loading, help retry on error
        categoriesApi
            .getAll()
            .then((response) => {
                setState({ categories: response.data, isFirstApiCallInProgress: false });
            })
            .catch((data) => {
                if (data.error && data.error.message && data.error.code !== 4000)
                    enqueueSnackbar(data.error.message, { variant: "error" });
                else enqueueSnackbar("Unknown error", { variant: "error" });
            });
    }, [enqueueSnackbar]);

    const onDeleteClick = (categoryId: string) => {
        let categoryName = "";
        setState(({ categories }) => {
            const newCategories = categories.map((eachCategory) => {
                if (eachCategory.id === categoryId) {
                    categoryName = eachCategory.name;
                    eachCategory.isApiCalling = true;
                }
                return eachCategory;
            });
            return { categories: newCategories };
        });
        categoriesApi
            .delete(categoryId)
            .then((response) => {
                const { data } = response;
                enqueueSnackbar(`"${categoryName}" Category successfully deleted`, { variant: "success" });

                setState((prevData) => {
                    const newCategories = prevData.categories.filter((eachCategory) => eachCategory.id !== data.id);
                    return { categories: newCategories };
                });
            })
            .catch((data) => {
                if (data.error && data.error.message && data.error.code !== 4000)
                    enqueueSnackbar(data.error.message, { variant: "error" });
                else enqueueSnackbar("Unknown error", { variant: "error" });
                setState(({ categories }) => {
                    const newCategories = categories.map((eachCategory) => {
                        if (eachCategory.id === categoryId) eachCategory.isApiCalling = false;
                        return eachCategory;
                    });

                    return {
                        categories: newCategories,
                    };
                });
            });
    };

    const handleEditCategoryClick = (categoryBeingEditedIndex: number) => {
        setState(({ categories }) => ({
            categoryBeingEdited: categories[categoryBeingEditedIndex],
            isCategoryDialogOpen: true,
        }));
    };

    const onEditCategoryComplete = (categoryId: string, updatedCategoryName: string) => {
        const indexOfCategoryBeingUpdated = state.categories.findIndex(
            (eachCategory) => eachCategory.id === categoryId,
        );
        const oldCategoryName = state.categories[indexOfCategoryBeingUpdated].name;
        if (state.categories[indexOfCategoryBeingUpdated].name === updatedCategoryName) return;
        //set api calling in category object
        setState(({ categories }) => {
            const newCategories = [...categories];
            newCategories[indexOfCategoryBeingUpdated].name = updatedCategoryName;
            newCategories[indexOfCategoryBeingUpdated].isApiCalling = true;
            return { categories: newCategories };
        });
        //call api
        categoriesApi
            .update(categoryId, updatedCategoryName)
            .then((response) => {
                enqueueSnackbar(
                    //TODO: Could be error
                    `"${oldCategoryName}" successfully updated to "${updatedCategoryName}"`,
                    {
                        variant: "success",
                    },
                );

                setState(({ categories }) => {
                    const newCategories = [...categories];
                    newCategories[indexOfCategoryBeingUpdated] = response.data;
                    return { categories: newCategories };
                });
            })
            .catch((data) => {
                setState(({ categories }) => {
                    const newCategories = [...categories];
                    newCategories[indexOfCategoryBeingUpdated].name = categories[indexOfCategoryBeingUpdated].name;
                    newCategories[indexOfCategoryBeingUpdated].isApiCalling = false;
                    return { categories: newCategories };
                });
                // console.log(_error);
                if (data.error && data.error.message && data.error.code !== 4000)
                    enqueueSnackbar(data.error.message, { variant: "error" });
                else enqueueSnackbar("Unknown error", { variant: "error" });
            });
    };

    const addNewCategory = (categoryName: string) => {
        setState({ isAddApiInProgress: true });
        categoriesApi
            .create(categoryName)
            .then((response) => {
                enqueueSnackbar(`"${categoryName}" Category Added Successfully`, {
                    variant: "success",
                });
                setState((prevData) => ({
                    categories: [...prevData.categories, response.data],
                    isAddApiInProgress: false,
                }));
            })
            .catch((data) => {
                setState({ isAddApiInProgress: false });
                if (data.error && data.error.message && data.error.code !== 4000)
                    enqueueSnackbar(data.error.message, { variant: "error" });
                else enqueueSnackbar("Unknown error", { variant: "error" });
            });
    };
    return (
        <Card className={`exp-nocard-xs ${classes.card}`}>
            <AddCategoryDialog
                categoryBeingEdited={state.categoryBeingEdited}
                onAddNewCategory={addNewCategory}
                open={state.isCategoryDialogOpen}
                onClose={() => setState({ isCategoryDialogOpen: false })}
                onUpdateCategory={onEditCategoryComplete}
            />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.headTableCell}>
                            <CategoryIcon className={classes.categoryIcon} />
                            <Typography variant="h6">Category</Typography>
                            <IconButton
                                disabled={state.isAddApiInProgress}
                                onClick={() => setState({ isCategoryDialogOpen: true, categoryBeingEdited: undefined })}
                                className={classes.addIcon}
                            >
                                {state.isAddApiInProgress ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    <AddCircleIcon />
                                )}
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state.categories.map(({ id, name, isApiCalling }, index) => (
                        <TableRow selected={isApiCalling} key={id}>
                            <TableCell className={classes.eachCategoryCell}>
                                <Typography display="inline" variant="body2">
                                    {name}
                                </Typography>
                                <IconButton onClick={() => handleEditCategoryClick(index)} className={classes.editIcon}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => onDeleteClick(id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                    {state.isFirstApiCallInProgress && (
                        <div className={classes.loaderContainer}>
                            <CircularProgress />
                        </div>
                    )}
                    {!state.isFirstApiCallInProgress && state.categories.length === 0 && (
                        <TableRow>
                            <TableCell align="center">No Categories</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Card>
    );
};

export default withStyles(Styles)(CategoryUpdater);
