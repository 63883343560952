import { createStyles, Theme } from "@material-ui/core";

const Styles = (theme: Theme) =>
    createStyles({
        flexMargin: {
            minHeight: 30,
            flexGrow: 1,
        },
        container: {
            // background: "rgba(0,0,0,0.1)",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
        },
        card: {
            padding: "48px 16px 40px",
            margin: "auto",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            [theme.breakpoints.only("xs")]: {
                boxShadow: "none",
                overflow: "auto",
                background: "none",
            },
            [theme.breakpoints.up("sm")]: {
                minWidth: 475,
                maxWidth: 475,
                padding: "48px 36px 40px",
                width: "auto",
            },
        },
        header: {
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(2),
        },
        forgotPasswordLinkItem: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            [theme.breakpoints.only("xs")]: {
                display: "flex",
                justifyContent: "center",
            },
        },
        signUpLinkGrid: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            display: "flex",
            justifyContent: "center",
            [theme.breakpoints.up("sm")]: {
                justifyContent: "flex-end",
            },
        },
    });

export default Styles;
