import { createStyles, Theme } from "@material-ui/core";
import { lighten } from "@material-ui/core/styles";

const Styles = (theme: Theme) =>
    createStyles({
        card: {
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up("sm")]: {
                padding: theme.spacing(3),
            },
        },
        highlight: {
            color: theme.palette.primary.main,
            backgroundColor: `${lighten(theme.palette.primary.light, 0.85)} !important`,
        },
        categoriesTableRoot: {
            marginTop: 8,
            marginBottom: 8,
        },
        headTableCell: {
            paddingLeft: 0,
            display: "flex",
            alignItems: "center",
        },
        categoryIcon: {
            marginRight: theme.spacing(1),
        },
        eachCategoryCell: {
            display: "flex",
            alignItems: "center",
        },
        editIcon: {
            marginLeft: "auto",
            marginRight: theme.spacing(1),
        },
        addIcon: {
            marginLeft: "auto",
        },
        loaderContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    });

export default Styles;

export const AddCategoryDialogStyles = (theme: Theme) =>
    createStyles({
        paper: {
            width: "80vw",
            padding: theme.spacing(3),
        },
        header: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(2),
        },
        headerIcon: {
            marginRight: theme.spacing(0.5),
        },
        updateButton: {
            marginRight: theme.spacing(1),
        },
        categoryInputField: {
            marginBottom: theme.spacing(3.5),
        },
    });
