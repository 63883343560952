import { createStyles, Theme } from "@material-ui/core";
const Styles = (theme: Theme) =>
    createStyles({
        paper: {
            width: "80vw",
            padding: theme.spacing(3),
        },
        header: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(2),
            "& > svg": {
                marginRight: theme.spacing(0.5),
            },
        },
    });

export default Styles;
