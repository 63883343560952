import { createMuiTheme } from "@material-ui/core";
import { deepPurple, cyan } from "@material-ui/core/colors";

const CustomTheme = createMuiTheme({
    // spacing: 16,
    overrides: {
        MuiCard: {
            root: {
                margin: 8,
            },
        },
        MuiTextField: {
            root: {
                marginBottom: 8,
            },
        },
        MuiButton: {
            root: {
                padding: "8px 16px",
                margin: 8,
            },
            fullWidth: {
                width: "calc(100% - 16px)",
            },
        },
        MuiTypography: {
            gutterBottom: {},
        },
    },
    palette: {
        primary: deepPurple,
        secondary: cyan,
        // text: { primary: "#00000" },
        background: {
            default: "#f9f9f9",
            // paper: "#212529",
        },
        text: {
            primary: "#000000",
            secondary: "rgba(0, 0, 0, 0.6)",
        },
    },
    typography: {
        allVariants: { fontFamily: `"roboto", sans-serif` },
        // h1: { fontWeight: "bold", lineHeight: 1.2 },
        //     h2: { fontSize: "2rem", fontWeight: "bold", lineHeight: 1.2 },
        //     h3: { fontSize: "1.8rem", fontWeight: "bold", lineHeight: 1.2 },
        h4: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "34px",
            lineHeight: "40px",
            letterSpacing: "0.25px",
        },
        h5: { fontFamily: "Roboto", fontStyle: "normal", fontWeight: "normal", fontSize: "24px", lineHeight: "28px" },
        h6: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "23px",
            letterSpacing: "0.15px",
        },
        body1: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "18px",
            lineHeight: "28px",
            letterSpacing: "0.5px",
        },
        body2: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.25px",
        },
        subtitle1: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.15px",
        },
        subtitle2: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "24px",
        },
        caption: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.4px",
        },
        overline: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "10px",
            lineHeight: "  16px",
            letterSpacing: "1.5px",
            textTransform: "uppercase",
        },
    },
});

export default CustomTheme;
