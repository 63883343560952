import React, { Fragment } from "react";
import { withStyles, WithStyles, Card, Typography, Box, CircularProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";
import MoneyIcon from "@material-ui/icons/Money";

import ButtonWithLoader from "../ButtonWithLoader";
import Budget from "../../utils/Network/Apis/Budget";
import useState from "../../utils/StateCustomHook";

import Styles from "./styles";
import BudgetUpdateDialog from "./BudgetUpdateDialog";

const budgetApi = new Budget();

interface BudgetSettingsState {
    isUpdateApiLoading: boolean;
    budget: number;
    isUpdateDialogOpen: boolean;
    isFirstApiCallLoading: boolean;
}
const BudgetSettings: React.FC<WithStyles<typeof Styles>> = ({ classes }): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();
    const [state, setState] = useState<BudgetSettingsState>({
        isFirstApiCallLoading: true,
        isUpdateDialogOpen: false,
        budget: 0,
        isUpdateApiLoading: false,
    });
    const updateBudget = (value: number) => {
        setState({ isUpdateApiLoading: true });
        /**
         * TODO: Api call and notify
         */
        budgetApi
            .updateBudget(value)
            .then((response) => {
                setState({ budget: response.data, isUpdateApiLoading: false });
                //enque budget updated successfully
                enqueueSnackbar(`Budget updated successfully to ${response.data}`, { variant: "success" });
            })
            .catch((data) => {
                if (data.error && data.error.message && data.error.code !== 4000)
                    enqueueSnackbar(data.error.message, { variant: "error" });
                else enqueueSnackbar("Unknown error", { variant: "error" });
            });
    };

    React.useEffect(() => {
        let isMounted = true; // this variable is for stopping setState from setting state on unmounted component
        budgetApi
            .getBudget()
            .then((response) => {
                if (isMounted) {
                    setState({ budget: response.data, isFirstApiCallLoading: false });
                }
            })
            .catch((data) => {
                //TODO: Improve error handling.
                if (isMounted) {
                    if (data.error && data.error.message && data.error.code !== 4000)
                        enqueueSnackbar(data.error.message, { variant: "error" });
                    else enqueueSnackbar("Unknown error", { variant: "error" });
                }
            });

        return () => {
            isMounted = false;
        };
    }, [enqueueSnackbar]);

    const handleDialog = (state: boolean) => {
        setState({ isUpdateDialogOpen: state });
    };

    return (
        <Fragment>
            <BudgetUpdateDialog
                onUpdateClick={updateBudget}
                currentBudget={state.budget}
                onClose={() => handleDialog(false)}
                open={state.isUpdateDialogOpen}
            />
            <Card className={`exp-nocard-xs ${classes.card}`}>
                <Typography variant="h5" className={classes.header}>
                    <Box clone mr={0.5}>
                        <MoneyIcon />
                    </Box>
                    Budget
                </Typography>

                <Box display="flex" alignItems="center">
                    <Box mr={0.5}>
                        <Typography color="textSecondary" variant="body1">
                            Current Budget :
                        </Typography>
                    </Box>
                    {state.isFirstApiCallLoading ? (
                        <CircularProgress />
                    ) : (
                        <Typography color="textPrimary" variant="body2">
                            ${state.budget.toFixed(2)}
                        </Typography>
                    )}

                    <ButtonWithLoader
                        variant="contained"
                        color="primary"
                        onClick={() => handleDialog(true)}
                        isLoading={state.isUpdateApiLoading}
                        disabled={state.isUpdateApiLoading}
                        className={classes.button}
                    >
                        Update
                    </ButtonWithLoader>
                </Box>
            </Card>
        </Fragment>
    );
};
export default withStyles(Styles)(BudgetSettings);
