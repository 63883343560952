import { createStyles, Theme } from "@material-ui/core";
const Styles = (theme: Theme) =>
    createStyles({
        card: {
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up("sm")]: {
                padding: theme.spacing(3),
            },
        },
        center: {
            display: "flex",
            alignItems: "center",
        },
        button: {
            marginLeft: "auto",
        },
        header: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(1),
        },
    });

export default Styles;
