import React from "react";
import { Formik, FormikHelpers, Form } from "formik";
import { withStyles, WithStyles, Card, Typography, Box, TextField, Link, Grid } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import * as yup from "yup";
import { withSnackbar, WithSnackbarProps } from "notistack";

import { ReactComponent as Logo } from "../../Assets/logo_icon.svg";
import ButtonWithLoader from "../../Components/ButtonWithLoader";
import MadeWithLove from "../../Components/MadeWithLove";
import FormikField from "../../Components/FormikField";
import Auth from "../../utils/Network/Apis/Auth";
import { storeToken } from "../../utils/tokens";
import Helmet from "../../Components/Helmet";

import Styles from "./styles";

const SignupSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup
        .string()
        .email("Must be an email address")
        .max(255, "Too Long!")
        .required("Email address is required for a new account"),
    password: yup.string().min(6, "Password Too Short!").max(50, "Password Too Long!").required("Password is required"),
});
class SignupPage extends React.Component<WithStyles<typeof Styles> & RouteComponentProps & WithSnackbarProps> {
    private authAPI = new Auth();

    formikInitialValues: yup.InferType<typeof SignupSchema> = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
    };

    handleFormSubmit = async (
        values: yup.InferType<typeof SignupSchema>,
        formikHelpers: FormikHelpers<yup.InferType<typeof SignupSchema>>,
    ) => {
        const { email, firstName, lastName, password } = values;
        try {
            const response = await this.authAPI.register(email, password, firstName, lastName);
            //route to dashboard
            storeToken(response.data.authToken);
            this.props.enqueueSnackbar("Signed up successfully", { variant: "success" });
            this.props.history.push("/");
        } catch (data) {
            if (data && data.error) {
                if (data.error.path) {
                    formikHelpers.setErrors({ [data.error.path]: data.error.message });
                } else {
                    this.props.enqueueSnackbar(data.error.message, { variant: "warning" });
                }
            } else this.props.enqueueSnackbar("Unknown error occurred", { variant: "error" });

            // if (error.error.code === 4001)
            formikHelpers.setSubmitting(false);
        }
    };
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <Helmet title="Sign up" />
                <Card className={classes.card}>
                    <Logo />
                    <Typography className={classes.header} variant="body1">
                        Sign Up
                    </Typography>
                    <Formik
                        onSubmit={this.handleFormSubmit}
                        initialValues={this.formikInitialValues}
                        validationSchema={SignupSchema}
                    >
                        {({ errors, touched, isSubmitting, submitForm }) => {
                            return (
                                <Form>
                                    <Grid container>
                                        <Grid xs={12} sm={6} item>
                                            <Box mr={[0, 1]}>
                                                <FormikField
                                                    autoFocus
                                                    disabled={isSubmitting}
                                                    Component={TextField}
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label="First Name"
                                                    name="firstName"
                                                    autoComplete="fname"
                                                    error={touched.firstName && errors.firstName !== undefined}
                                                    helperText={
                                                        touched.firstName && errors.firstName !== undefined
                                                            ? errors.firstName
                                                            : " "
                                                    }
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} sm={6} item>
                                            <Box ml={[0, 1]}>
                                                <FormikField
                                                    Component={TextField}
                                                    disabled={isSubmitting}
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    label="Last Name"
                                                    name="lastName"
                                                    autoComplete="lname"
                                                    error={touched.lastName && errors.lastName !== undefined}
                                                    helperText={
                                                        touched.lastName && errors.lastName !== undefined
                                                            ? errors.lastName
                                                            : " "
                                                    }
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <FormikField
                                        Component={TextField}
                                        disabled={isSubmitting}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        label="Email Address"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        error={touched.email && errors.email !== undefined}
                                        helperText={touched.email && errors.email !== undefined ? errors.email : " "}
                                    />
                                    <FormikField
                                        Component={TextField}
                                        disabled={isSubmitting}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        label="Password"
                                        name="password"
                                        type="password"
                                        autoComplete="password"
                                        error={touched.password && errors.password !== undefined}
                                        helperText={
                                            touched.password && errors.password !== undefined ? errors.password : " "
                                        }
                                    />

                                    <ButtonWithLoader
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        isLoading={isSubmitting}
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                        type="submit"
                                    >
                                        Sign Up
                                    </ButtonWithLoader>
                                </Form>
                            );
                        }}
                    </Formik>
                    <Link
                        className={classes.signInLink}
                        href="/signin"
                        variant="body2"
                        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                            event.preventDefault();
                            this.props.history.push("/signin");
                        }}
                    >
                        {"Have an account? Sign in"}
                    </Link>

                    <MadeWithLove />
                </Card>
            </div>
        );
    }
}

export default withStyles(Styles)(withSnackbar(SignupPage));
