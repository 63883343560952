import React from "react";
import withStyles, { WithStyles } from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { RouteComponentProps } from "react-router-dom";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Formik, FormikHelpers, Form } from "formik";
import * as yup from "yup";

import Helmet from "../../Components/Helmet";
import { ReactComponent as Logo } from "../../Assets/logo_icon.svg";
import MadeWithLove from "../../Components/MadeWithLove";
import ButtonWithLoader from "../../Components/ButtonWithLoader";
import { storeToken } from "../../utils/tokens";
import Auth from "../../utils/Network/Apis/Auth";
import FormikField from "../../Components/FormikField";

import Styles from "./styles";

const SigninSchema = yup.object().shape({
    email: yup.string().email("Must be an email address").max(255, "Too Long!").required("Required"),
    password: yup.string().min(6, "Too Short!").max(50, "Too Long!").required("Required"),
});
const authAPI = new Auth();
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps extends WithStyles<typeof Styles>, RouteComponentProps, WithSnackbarProps {}
const SigninPage: React.SFC<IProps> = ({ history, classes, enqueueSnackbar }) => {
    const formikInitialValues: yup.InferType<typeof SigninSchema> = {
        email: "",
        password: "",
    };

    const handleFormSubmit = async (
        values: yup.InferType<typeof SigninSchema>,
        formikHelpers: FormikHelpers<yup.InferType<typeof SigninSchema>>,
    ) => {
        const { email, password } = values;
        try {
            const response = await authAPI.login(email, password);
            enqueueSnackbar("Yuhuu, Logged in successfully!", { variant: "success" });
            //TODO: Stateful signin
            //sucessful signin
            storeToken(response.data.authToken);
            //store token
            //route to Dashboard
            //check for state in location
            history.push("/");
        } catch (data) {
            if (data && data.error) {
                if (data.error.path) {
                    formikHelpers.setErrors({ [data.error.path]: data.error.message });
                } else {
                    enqueueSnackbar(data.error.message, { variant: "error" });
                }
            } else enqueueSnackbar("Unknown error occurred", { variant: "error" });
            formikHelpers.setSubmitting(false);
        }
    };

    return (
        <div className={classes.container}>
            <Helmet title="Sign in" />
            <Card className={classes.card}>
                <Logo />

                <Typography className={classes.header} variant="body1">
                    Sign In
                </Typography>

                <Formik onSubmit={handleFormSubmit} initialValues={formikInitialValues} validationSchema={SigninSchema}>
                    {({ errors, touched, isSubmitting, submitForm }) => {
                        return (
                            <Form>
                                <FormikField
                                    Component={TextField}
                                    disabled={isSubmitting}
                                    variant="outlined"
                                    // margin="normal"
                                    required
                                    autoFocus
                                    fullWidth
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    error={touched.email && errors.email !== undefined}
                                    helperText={touched.email && errors.email !== undefined ? errors.email : " "}
                                />

                                <FormikField
                                    Component={TextField}
                                    disabled={isSubmitting}
                                    variant="outlined"
                                    // margin="normal"
                                    // className={props.className + " hello"}
                                    required
                                    fullWidth
                                    label="Password"
                                    name="password"
                                    type="password"
                                    autoComplete="password"
                                    error={touched.password && errors.password !== undefined}
                                    helperText={
                                        touched.password && errors.password !== undefined ? errors.password : " "
                                    }
                                />

                                <ButtonWithLoader
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    isLoading={isSubmitting}
                                    onClick={submitForm}
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    Sign In
                                </ButtonWithLoader>
                            </Form>
                        );
                    }}
                </Formik>
                <Grid container>
                    <Grid item xs={12} sm="auto" className={classes.forgotPasswordLinkItem}>
                        <Link href="#" variant="body2">
                            Forgot password?
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm className={classes.signUpLinkGrid}>
                        <Link
                            href="/signup"
                            variant="body2"
                            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                                event.preventDefault();
                                history.push("/signup");
                            }}
                        >
                            {"Don't have an account? Sign Up"}
                        </Link>
                    </Grid>
                </Grid>
                <MadeWithLove />
            </Card>
        </div>
    );
};
export default withSnackbar(withStyles(Styles)(SigninPage));
