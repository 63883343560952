import { createStyles, Theme } from "@material-ui/core";
const drawerWidth = 240;
const Styles = (theme: Theme) =>
    createStyles({
        drawer: {
            width: drawerWidth,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        toolbar: { ...theme.mixins.toolbar, [theme.breakpoints.down("sm")]: theme.mixins.toolbar },
        appBar: {
            ...theme.mixins.toolbar,
            alignItems: "center",
            justifyContent: "center",
        },
    });

export default Styles;
