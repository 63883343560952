import Router from "../router";
import { ExpensesRoute } from "../ApiRoutes";
import { getToken } from "../../tokens";

import { CategoryInterface } from "./Categories";

export interface ExpenseInterface {
    category: CategoryInterface;
    name: string;
    amount: number;
    expenseDate: number;
    createdAt: number;
    id?: string;
    isDeleted: boolean;
    createdBy: string;
    description: string;
}

type ExpenseDataInterface = Omit<ExpenseInterface, "createdAt" | "isDeleted" | "createdBy" | "id" | "category"> & {
    category: string;
};

class Expenses extends Router {
    constructor() {
        super(ExpensesRoute);
    }
    // private route = CategoriesRoute;
    public getAll = async () => {
        try {
            const token = getToken();
            if (token === null) throw new Error("no token");
            return await this.request<ExpenseInterface[]>({
                method: this.GET,
                headers: { Authorization: "Bearer " + token },
            });
        } catch (error) {
            if (error.response) throw error.response.data;
            else throw error;
        }

        //form all the params
        // return request promise
    };

    public create = async (expense: ExpenseDataInterface) => {
        try {
            const token = getToken();
            if (token === null) throw new Error("no token");
            return await this.request<ExpenseInterface>({
                method: this.POST,
                headers: { Authorization: "Bearer " + token },
                data: expense,
            });
        } catch (error) {
            if (error.response) throw error.response.data;
            else throw error;
        }
    };

    public delete = async (id: string) => {
        try {
            const token = getToken();
            if (token === null) throw new Error("no token");
            return await this.request<CategoryInterface>({
                url: this.route + "/" + id,
                method: this.DELETE,
                headers: { Authorization: "Bearer " + token },
            });
        } catch (error) {
            if (error.response) throw error.response.data;
            else throw error;
        }
    };

    public update = async (id: string, expense: Partial<ExpenseDataInterface>) => {
        try {
            const token = getToken();
            if (token === null) throw new Error("no token");
            const response = await this.request<ExpenseInterface>({
                url: this.route + "/" + id,
                method: this.PUT,
                headers: { Authorization: "Bearer " + token },
                data: expense,
            });
            return response;
        } catch (error) {
            if (error.response) throw error.response.data;
            else throw error;
        }
    };
}

export default Expenses;
